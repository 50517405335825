<template>
  <div id="app">
    <header>
      <h1 v-if="account"><a href="https://opensea.io/collection/blockzero-1" target="_blank">blockzero</a></h1>
      <span v-if="account" class="pill">
        <svg width="18" height="18" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M110.034 55C110.034 85.3756 85.359 110 54.921 110C26.0432 110 2.35281 87.8354 0 59.6232H72.8467V50.3768H0C2.35281 22.1646 26.0432 0 54.921 0C85.359 0 110.034 24.6243 110.034 55ZM314.358 100.036C333.455 100.036 345.98 89.7462 345.98"
            fill="black"></path>
        </svg>&nbsp;
        {{ account.slice(0, 7) }}...
      </span>
    </header>

    <div class="content">
      <div class="error" v-if="error">{{ error }}</div>
      <div class="status" v-if="!account">
        <span class="muted">BLOCKZERO</span>
        <span class="head">welcome to blockzero</span>
        <span class="sub-head">connect your wallet to see your <a href="https://opensea.io/collection/athszn">athszn</a> tokens.</span>

        <button @click="connectWallet" class="pill">connect wallet</button>
      </div>
      <div class="status" v-if="loading">Loading NFTs...</div>
      <div v-if="nfts" class="grid">
        <div v-for="nft in nfts" :key="nft.identifier" class="grid-item"
          :class="{ active: activeItems.includes(nft.identifier) }"
          @click="toggleItem(nft.identifier, nft.name.split(' ')[0])">
          <img :src="nft.image_url" alt="image" />
          <div class="texts">
            <span class="trait">{{ nft.name.split(" ")[0] }}</span>
            <span class="id">{{ nft.name.split(" ")[1] }}</span>
          </div>
        </div>
      </div>
    </div>

    <footer v-if="activeItems.length == 2">
      <div class="stats">
        <div class="will-be-burned">
          <span class="head-text">
            will be burned
          </span>
          <div class="item">
            <span class="trait">{{ itemTraits[0] }}</span> <span class="id">#{{ activeItems[0] }}</span>
          </div>
          <div class="item">
            <span class="trait">{{ itemTraits[1] }}</span> <span class="id">#{{ activeItems[1] }}</span> <br>
          </div>
        </div>

        <div class="you-will-get">
          <span class="head-text">
            you'll get
          </span>
          <div class="item">
            <span class="trait">{{ calculateTrait(itemTraits[0], itemTraits[1]) }}</span>
          </div>
        </div>
      </div>

      <button @click="burnAndMintNFTs" v-if="activeItems.length === 2 && isActive === true" class="burn">burn</button>
      <button v-if="activeItems.length === 2 && isActive === false" class="burn disabled" disabled>you cannot burn</button>
    </footer>
  </div>
</template>

<script>
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import { CONTRACT_ADDRESS, CONTRACT_ABI } from './config.js';
import { EXISTING_NFT_ABI, EXISTING_CONTRACT_ADDRESS } from './existing.js';

export default {
  data() {
    return {
      web3: null,
      account: null,
      //targetNetworkId: '11155111',
      //targetNetworkName: 'Sepolia',
      targetNetworkId: '8453',
      targetNetworkName: 'Base',
      nfts: null,
      loading: false,
      error: null,
      activeItems: [],
      itemTraits: [],
      contract: null,
      existingNFTContract: null,
      isActive: false,
    };
  },
  mounted() {
    this.checkConnection()
  },
  methods: {
    async connectWallet() {
      const provider = await detectEthereumProvider();
      if (provider) {
        this.web3 = new Web3(provider);
        provider.on('chainChanged', this.handleChainChanged);
        try {
          await this.switchNetwork();
          await this.getAccount();
          this.initContract();
          localStorage.setItem('isWalletConnected', true);
          await this.getNFTs();
        } catch (error) {
          console.error(error);
          //this.error = 'Failed to connect wallet';
        }
      } else {
        console.log('Please install MetaMask!');
        //this.error = 'MetaMask is not installed';
      }
    },
    async initContract() {
      this.contract = new this.web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
      this.existingNFTContract = new this.web3.eth.Contract(EXISTING_NFT_ABI, EXISTING_CONTRACT_ADDRESS);
      this.isActive = await this.contract.methods._isActive().call();

      if (this.isActive == false) {
        this.error = "stage isn't started yet."
      }
    },
    calculateTrait(first, second) {
      const rareYears = [2011, 2024];
      const averageYears = [2013, 2021];
      const commonYears = [2017, 2020];

      const firstYear = parseInt(first);
      const secondYear = parseInt(second);

      const getRandomElement = (elements) => elements[Math.floor(Math.random() * elements.length)];

      if (rareYears.includes(firstYear) && rareYears.includes(secondYear)) {
        return 'satoshi';
      }
      if ((rareYears.includes(firstYear) && averageYears.includes(secondYear)) ||
        (rareYears.includes(secondYear) && averageYears.includes(firstYear))) {
        return 'timestamp';
      }
      if ((rareYears.includes(firstYear) && commonYears.includes(secondYear)) ||
        (rareYears.includes(secondYear) && commonYears.includes(firstYear))) {
        return '74696d657374616d70';
      }

      if (averageYears.includes(firstYear) && averageYears.includes(secondYear)) {
        return '0';
      }
      if ((averageYears.includes(firstYear) && commonYears.includes(secondYear)) ||
        (averageYears.includes(secondYear) && commonYears.includes(firstYear))) {
        return '170';
      }

      // Common combinations
      if (commonYears.includes(firstYear) && commonYears.includes(secondYear)) {
        return 'territory of freedom';
      }

      // If none of the above conditions are met, check mixed combinations
      if ((rareYears.includes(firstYear) || rareYears.includes(secondYear)) ||
        (averageYears.includes(firstYear) || averageYears.includes(secondYear)) ||
        (commonYears.includes(firstYear) || commonYears.includes(secondYear))) {
        if ((averageYears.includes(firstYear) && commonYears.includes(secondYear)) ||
          (averageYears.includes(secondYear) && commonYears.includes(firstYear))) {
          return getRandomElement(['genesis', 'alpaca socks']);
        }
        if ((commonYears.includes(firstYear) && averageYears.includes(secondYear)) ||
          (commonYears.includes(secondYear) && averageYears.includes(firstYear))) {
          return getRandomElement(['genesis', 'alpaca socks']);
        }
        if (commonYears.includes(firstYear) || commonYears.includes(secondYear)) {
          return '50';
        }
      }

      // Default fallback
      return getRandomElement(['sat1', 'sat2']);
    },
    toggleItem(id, trait) {
      const index = this.activeItems.indexOf(id);
      const traitIndex = this.itemTraits.indexOf(trait);
      if (index > -1 && traitIndex > -1) {
        // If the item is already active, remove it
        this.activeItems.splice(index, 1);
        this.itemTraits.splice(traitIndex, 1)
      } else {
        // If the item is not active, add it only if there are fewer than 2 active items
        if (this.activeItems.length < 2) {
          this.activeItems.push(id);
          this.itemTraits.push(trait);
        }
      }
    },
    async switchNetwork() {
      const chainId = `0x${parseInt(this.targetNetworkId, 10).toString(16)}`;
      try {
        await this.web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }]
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          await this.web3.currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId,
                chainName: this.targetNetworkName,
                rpcUrls: ['https://mainnet.base.org'],
                nativeCurrency: {
                  name: 'Base ETH',
                  symbol: 'ETH',
                  decimals: 18
                },
                blockExplorerUrls: ['https://basescan.org']
              }
            ]
          });
          /*await this.web3.currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId,
                chainName: this.targetNetworkName,
                rpcUrls: ['https://sepolia.infura.io/v3/'],
                nativeCurrency: {
                  name: 'Sepolia ETH',
                  symbol: 'ETH',
                  decimals: 18
                },
                blockExplorerUrls: ['https://sepolia.etherscan.io']
              }
            ]
          });*/
        } else {
          console.log(switchError);
          this.error = 'failed to switch network';
        }
      }
    },
    async getAccount() {
      const accounts = await this.web3.eth.requestAccounts();
      this.account = accounts[0];
    },
    async handleChainChanged(chainId) {
      if (chainId !== `0x${parseInt(this.targetNetworkId, 10).toString(16)}`) {
        await this.switchNetwork();
      }
    },
    async checkConnection() {
      const isWalletConnected = localStorage.getItem('isWalletConnected');
      if (isWalletConnected) {
        await this.connectWallet();
      }
    },
    async getNFTs() {
      if (this.account) {
        this.loading = true;
        this.error = null;
        const options = {
          method: 'GET',
          headers: { accept: 'application/json', 'x-api-key': '433266b9f4cd46b2af1ef7e59e009d41' }
        };
        try {
          const response = await fetch(`https://api.opensea.io/api/v2/chain/base/account/${this.account}/nfts?collection=athszn`, options);
          const data = await response.json();
          console.log(data.nfts);
          this.nfts = data.nfts;

          if (data.nfts.length <= 0) {
            this.error = 'you don\'t have an ATHSZN token.';
          }
        } catch (err) {
          console.error(err);
          this.error = 'failed to fetch tokens';
        } finally {
          this.loading = false;
        }
      }
    },
    async burnAndMintNFTs() {
      if (this.activeItems.length !== 2) {
        return;
      }

      const [firstNFTId, secondNFTId] = this.activeItems;

      const firstTrait = this.itemTraits[0]; // Replace with actual trait data
      const secondTrait = this.itemTraits[1]; // Replace with actual trait data

      const rarity = this.calculateTrait(firstTrait, secondTrait);

      try {
        const isApproved = await this.existingNFTContract.methods.isApprovedForAll(this.account, CONTRACT_ADDRESS).call();
        if (!isApproved) {
          await this.existingNFTContract.methods.setApprovalForAll(CONTRACT_ADDRESS, true).send({ from: this.account });
          alert('approval set successfully. please try burning and minting again.');
          return;
        }

        const tx = await this.contract.methods.mintWithTraits(firstNFTId, secondNFTId, firstTrait, secondTrait, rarity).send({ from: this.account });
        console.log('Transaction:', tx);

        // Capture the transaction details
        const transactionDetails = {
          firstNFTId,
          secondNFTId,
          firstTrait,
          secondTrait,
          newNFTId: tx.events.Transfer.returnValues.tokenId,
          rarity,
          transactionHash: tx.transactionHash
        };

        console.log(transactionDetails)
        alert(`tokens burned and new token minted successfully`);
        this.activeItems = []; // Clear the selected items
        await this.getNFTs(); // Refresh the NFTs
      } catch (error) {
        console.error('Error burning and minting NFTs:', error);
        this.error = 'failed to burn and mint NFTs';
      }
    },
  }
};
</script>

<style>
* {
  font-family: 'InterDisplay', sans-serif !important;
  margin: 0;
  padding: 0;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.006em;
}

h1 {
  font-size:1.75rem;
  
}
h1 >a {
  text-decoration: none;
  color:#000;
}

.error {
  z-index: 99;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.content>.status {
  font-size: 1.3rem;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content>.status>.muted {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.2);
  font-weight: 300;
  letter-spacing: 0.5em;
  margin-bottom: 15px;
  font-family: 'Clash Display' !important;
}

.content>.status>.head {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 7.5px;
}

.content>.status>.sub-head {
  font-size: 1.3rem;
  font-weight: 500;
  width: 500px;
  text-align: center;
  margin-bottom: 25px;
}

.content>.status>.sub-head>a {
  color: #000;
}

.burn.disabled {
  background:#525252;
}
.burn.disabled:hover {
  background:#525252;
}

footer>div {
  display: flex;
}

footer>div>div {
  margin-right: 25px;
}

footer>.burn {
  background: #222;
  color: #fff;
  font-size: 1.25rem;
  border-radius: 10px;
  padding: 15px 25px;
  font-weight: 600;
  cursor: pointer;
  transition: all 250ms;
  border: none;
  height: 100%;
}

footer>.burn:hover {
  scale: 1.025;
  background: #050505;
}

footer>.burn:active {
  scale: 1;
}

footer>div>div>.head-text {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 5px;
}

footer>div>div>.item>.trait {
  font-size: 1.2rem;
  font-weight: 600;
}

footer>div>div>.item>.id {
  font-size: 1.2rem;
  font-weight: 500;
  color: rgba(0, 0, 0, .25);

}

body {
  padding: 0;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 600px;
  height: 75px;
  padding: 25px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 5;
}

footer {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  margin: 0 auto;
  width: 600px;
  padding: 25px;
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 5;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 15px;
}

.pill {
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 10px;
  font-size: 1.15rem;
  background: #f9f9f9;
  color: #111;
  font-weight: 600;
  cursor: pointer;
  transition: scale 250ms;
  display: flex;
  align-items: center;
  z-index: 99;
}

.pill:hover {
  scale: 1.025;
}

.content {
  margin: 125px auto;
  max-width: 600px;
  padding: 10px;
}


.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  text-align: center;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.grid-item.active {
  border-color: #1375ff;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: block;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.025);
}

.texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.texts>.id {
  font-size: 0.80rem;
  color: rgba(0, 0, 0, .3);
  font-weight: 400;
}

.texts>.trait {
  font-size: 1.15rem;
  color: #111;
  font-weight: 600;
}

@media (max-width: 626px) {

  header,
  footer {
    width: calc(100% - 20px);
  }

  footer {
    flex-direction: column;
  }

  footer>div {
    margin-bottom: 25px;
  }

  footer>.burn {
    width: 100%;
  }

  .pill {
    margin-top: 10px;
  }
}
</style>
